import React from 'react'
import { injectIntl } from 'react-intl'
import { graphql } from 'gatsby'

import withContext from '../components/withContext'
import injectContext from '../components/injectContext'
import withIntl from '../components/withIntl'
import Space from '../components/Space'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Navigation from '../scenes/Navigation'
import Header from '../scenes/Header'
import Sources from '../scenes/Integrations/Sources'
import Incoming from '../scenes/Integrations/Incoming'
import OptimizeWorkflow from '../scenes/OptimizeWorkflow'
import SecurityMini from '../scenes/SecurityMini'
import Footer from '../scenes/Footer'

import headerPicture from '../img/artworks/artwork_features_miniblock_2.png'

const GATSBY_APP_URL = process.env.GATSBY_APP_URL

const Integrations = ({ intl: { formatMessage }, data }) => (
  <Layout>
    <SEO
      metaTitle={formatMessage({ id: 'meta.integrations.title' })}
      metaDescription={formatMessage({ id: 'meta.integrations.description' })}
      metaOGImage={headerPicture}
    />
    <Navigation />
    <Header
      crumbs={[
        { name: formatMessage({ id: 'breadcrumb.wuha' }), path: '/' },
        { name: formatMessage({ id: 'breadcrumb.features' }), path: '/features' },
        { name: formatMessage({ id: 'breadcrumb.integrations' }), path: '/integrations' },
      ]}
      artworkPicture={data.headerPicture.childImageSharp.fluid}
      artworkWidth={440}
      title={formatMessage({ id: 'integrations.header.title' })}
      description={formatMessage({ id: 'integrations.header.description' })}
      actionText={formatMessage({ id: 'integrations.header.action.connect' })}
      actionURL={`${GATSBY_APP_URL}/sign-up?utm_source=wuha&utm_medium=cta&utm_content=integrations-header`}
    />
    <Space type="xxl" />
    <Sources />
    <Space type="giant" />
    <Incoming />
    <Space type="giant" />
    <OptimizeWorkflow
      utmContent="integrations-optimize"
    />
    <Space type="giant" />
    <SecurityMini />
    <Footer />
  </Layout>
)


export const pageQuery = graphql`
  query {
    headerPicture: file(relativePath: { eq: "artworks/artwork_features_miniblock_2.png" }) {
      ...fluidImage
    }
  }
`

export default withContext(injectContext(withIntl(injectIntl(Integrations))))
