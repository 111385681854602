import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Col } from 'react-grid-system'

import Space from '../../../components/Space'
import Source from '../components/Source'

const containerClass = classNames({
  'integrations-incoming-container': true,
})

const textContainerClass = classNames({
  'integrations-incoming-text-container': true,
})

const sourcesContainerClass = classNames({
  'integrations-incoming-list-container': true,
})

class Incoming extends Component {

  constructor(props) {
    super(props)

    this.state = {
      sources: [],
    }
  }

  componentDidMount() {
    const sources = [
      {
        id: 'gcalendar',
      },
      {
        id: 'el',
      },
      {
        id: 'asana',
      },
      {
        id: 'quickbooks',
      },
      {
        id: 'salesforce',
      },
      {
        id: 'evernote',
      },
      {
        id: 'trello',
      },
    ]

    this.setState({
      sources,
    })
  }


  // Render
  render() {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Container>
        <Row>
          <Col xs={12}>
            <div className={containerClass}>
              <div className={textContainerClass}>
                <h2>
                  {formatMessage({ id: `integrations.incoming.title` })}
                </h2>
                <Space type="m" />
                <p>
                  {formatMessage({ id: `integrations.incoming.description` })}
                </p>
              </div>
              <Space type="l" />
              {this.renderSources()}
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  renderSources = () => {
    const {
      sources,
    } = this.state
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    const list = sources.map(source => (
      <Source
        key={source.id}
        id={source.id}
        title={formatMessage({ id: `integrations.incoming.${source.id}.title` })}
      />
    ))

    return (
      <div className={sourcesContainerClass}>
        {list}
      </div>
    )
  }
}

export default injectIntl(Incoming)
