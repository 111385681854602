import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const containerClass = classNames({
  'integrations-source-container': true,
})

const textContainerClass = classNames({
  'integrations-source-text-container': true,
})

const titleTextClass = classNames({
  'integrations-source-title-text': true,
})

const comingTextClass = classNames({
  'integrations-source-coming-text': true,
})

class Source extends Component {

  render() {
    const {
      title,
      coming,
      isComingSoon,
    } = this.props

    return (
      <div className={containerClass}>
        <div className={textContainerClass}>
          <p className={titleTextClass}>
            {title}
          </p>
          {
            isComingSoon && (
              <p className={comingTextClass}>
                {coming}
              </p>
            )
          }
        </div>
      </div>
    )
  }
}

Source.propTypes = {
  id: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  coming: PropTypes.string,
  isComingSoon: PropTypes.bool,
}

Source.defaultProps = {
  id: undefined,
  logo: undefined,
  title: undefined,
  coming: undefined,
  isComingSoon: false,
}

export default Source
