import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Col, Visible } from 'react-grid-system'

import Image from '../../../components/Image'
import Space from '../../../components/Space'
import Source from '../components/Source'

import storagePicture from '../../../img/artworks/artwork_catalogue_1.png'
import messagingPicture from '../../../img/artworks/artwork_catalogue_2.png'
import legalPicture from '../../../img/artworks/artwork_catalogue_3.png'

const containerClass = classNames({
  'integrations-sources-container': true,
})

const contentContainerClass = classNames({
  'integrations-sources-content-container': true,
})

const sourcesContainerClass = classNames({
  'integrations-sources-list-container': true,
})

class Sources extends Component {

  constructor(props) {
    super(props)

    this.state = {
      sources: [],
    }
  }

  componentDidMount() {
    const sources = [
      {
        id: 'box',
        type: 'storage',
        isAvailable: false,
      },
      {
        id: 'dropbox',
        type: 'storage',
        isComing: false,
      },
      {
        id: 'gdrive',
        type: 'storage',
        isComing: false,
      },
      {
        id: 'msonedrive',
        type: 'storage',
        isComing: false,
      },
      {
        id: 'fs_macos',
        type: 'storage',
        isComing: false,
      },
      {
        id: 'mssharepoint',
        type: 'storage',
        isComing: false,
      },
      {
        id: 'fs_mswindows',
        type: 'storage',
        isComing: false,
      },
      {
        id: 'slack',
        type: 'messaging',
        isComing: false,
      },
      {
        id: 'msoutlook',
        type: 'messaging',
        isComing: false,
      },
      {
        id: 'gmail',
        type: 'messaging',
        isComing: false,
      },
      {
        id: 'efl',
        type: 'legal',
        isComing: false,
      },
      {
        id: 'dalloz',
        type: 'legal',
        isComing: false,
      },
    ]

    this.setState({
      sources,
    })
  }


  // Methods
  getSourcesByType = (type) => {
    const {
      sources,
    } = this.state

    return sources.filter(source => source.type === type)
  }

  // Render
  render() {
    return (
      <Container>
        <div className={containerClass}>
          {this.renderStorage()}
          <Space type="xxl" />
          {this.renderMessaging()}
          <Space type="xxl" />
          {this.renderLegal()}
        </div>
      </Container>
    )
  }

  renderStorage = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <Col xs={12} md={4}>
          <div className={contentContainerClass}>
            <Image
              src={storagePicture}
              alt="storage"
              width={288}
              style={{
                maxWidth: '100%',
              }}
            />
            <Space type="xs" />
            <h2>
              {formatMessage({ id: 'integrations.sources.storage.title' })}
            </h2>
            <Space type="m" />
            <p>
              {formatMessage({ id: 'integrations.sources.storage.description' })}
            </p>
            <ul>
              <li>
                {formatMessage({ id: 'integrations.sources.storage.point.1' })}
              </li>
              <li>
                {formatMessage({ id: 'integrations.sources.storage.point.2' })}
              </li>
              <li>
                {formatMessage({ id: 'integrations.sources.storage.point.3' })}
              </li>
              <li>
                {formatMessage({ id: 'integrations.sources.storage.point.4' })}
              </li>
            </ul>
          </div>
        </Col>
        {this.renderSources('storage')}
      </Row>
    )
  }

  renderMessaging = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <Col xs={12} md={4}>
          <div className={contentContainerClass}>
            <Image
              src={messagingPicture}
              alt="messaging"
              width={155}
              style={{
                maxWidth: '100%',
              }}
            />
            <Space type="xs" />
            <h2>
              {formatMessage({ id: 'integrations.sources.messaging.title' })}
            </h2>
            <Space type="m" />
            <p>
              {formatMessage({ id: 'integrations.sources.messaging.description' })}
            </p>
            <ul>
              <li>
                {formatMessage({ id: 'integrations.sources.messaging.point.1' })}
              </li>
              <li>
                {formatMessage({ id: 'integrations.sources.messaging.point.2' })}
              </li>
              <li>
                {formatMessage({ id: 'integrations.sources.messaging.point.3' })}
              </li>
              <li>
                {formatMessage({ id: 'integrations.sources.messaging.point.4' })}
              </li>
            </ul>
          </div>
        </Col>
        {this.renderSources('messaging')}
      </Row>
    )
  }

  renderLegal = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <Col xs={12} md={4}>
          <div className={contentContainerClass}>
            <Image
              src={legalPicture}
              alt="legal"
              width={200}
              style={{
                maxWidth: '100%',
              }}
            />
            <Space type="xs" />
            <h2>
              {formatMessage({ id: 'integrations.sources.legal.title' })}
            </h2>
            <Space type="m" />
            <p>
              {formatMessage({ id: 'integrations.sources.legal.description' })}
            </p>
            <ul>
              <li>
                {formatMessage({ id: 'integrations.sources.legal.point.1' })}
              </li>
              <li>
                {formatMessage({ id: 'integrations.sources.legal.point.2' })}
              </li>
              <li>
                {formatMessage({ id: 'integrations.sources.legal.point.3' })}
              </li>
              <li>
                {formatMessage({ id: 'integrations.sources.legal.point.4' })}
              </li>
              <li>
                {formatMessage({ id: 'integrations.sources.legal.point.5' })}
              </li>
            </ul>
          </div>
        </Col>
        {this.renderSources('legal')}
      </Row>
    )
  }

  renderSources = (type) => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    const sources = this.getSourcesByType(type)
    const list = sources.map(source => (
      <Source
        key={source.id}
        id={source.id}
        title={formatMessage({ id: `integrations.sources.${source.id}.title` })}
        isComing={source.isComing}
      />
    ))

    return (
      <Col xs={12} md={5} offset={{ md: 3 }}>
        <Visible xs sm>
          <Space type="m" />
        </Visible>
        <div className={sourcesContainerClass}>
          {list}
        </div>
      </Col>
    )
  }
}

export default injectIntl(Sources)
